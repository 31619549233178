import * as React from 'react';
import { graphql } from 'gatsby';
import ContextConsumer from '../../helpers/context';

import Template from '../../templates/offer';

const CoRobimyPage = ({ data }) => {
  const {
    title,
    intro,
    bigText,
    smallText,
    seoTitle,
    seoDescription,
    seoImage,
    areaOfExpertises,
  } = data.med.pageOffer;

  const contact = data.med.pageContact;

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Template
          title={title}
          intro={intro}
          bigText={bigText}
          smallText={smallText}
          seoTitle={seoTitle}
          items={areaOfExpertises}
          seoDescription={seoDescription}
          seoImage={seoImage}
          contextData={data}
          contextSet={set}
          langTo={'/info'}
          contact={contact}
        />
      )}
    </ContextConsumer>
  );
};

export const pageCoRobimyQuery = graphql`
  {
    med {
      pageOffer(where: { id: "ckjtsw44o45iy0b53dezcalbw" }, locales: en) {
        title
        intro
        bigText {
          markdown
          html
          raw
        }
        smallText {
          markdown
          html
          raw
        }
        areaOfExpertises {
          title
          paragraph
        }
        seoTitle
        seoDescription
        seoImage {
          url
        }
      }
      pageContact(where: { id: "ckjtspcaw2okg0b53vcsq2p8a" }, locales: pl_PL) {
        heading1
        email
        heading2
        address
        mapsUrl
      }
    }
  }
`;

export default CoRobimyPage;
